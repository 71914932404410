.carrosel{
    gap: 20px;
    display: flex;
    overflow-x: auto;
    // justify-content: center;
    img{
        max-height: 70px;
    }
}

.titulo {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
    margin-top: 1rem;
    font-family: 'Montserrat', sans-serif;
    color: #f07d0e;
}