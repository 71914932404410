.header{
    min-height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    box-shadow: 0 0 10px rgba(0,0,0,5%);
    background-color: white;
    z-index: 2;
    img{
        width: 8rem;
    }
    a{
        text-decoration: none;
        color: #f07d0e;
        font-size: 1.2rem;
        font-weight: 600;
        transition: color 0.2s;
        &:hover{
            color: black;
        }
    }
    &.container-fluid{
        padding: 0px 5rem;
    }
}
