.depoimentos {
    margin-top: 6rem;

    /* this card is inspired form this - https://georgefrancis.dev/ */

    .card {
        border: 0;
        padding: 1rem;
        // cursor: pointer;
        border-radius: 5px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        margin-top: 10px;
    }
    a{
        text-decoration: none;
    }
    .card>*+* {
        margin-top: 1.1em;
    }

    .card .card__content {
        font-size: 0.86rem;
    }

    .card .card__title {
        color: #f07d0e;
        padding: 0;
        font-size: 1.3rem;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .card .card__date {
        color: #6e6b80;
        font-size: 0.8rem;
    }

    /* hover */
}

@media (max-width: 400px) {
    .titulo {
        font-size: 2rem !important;
    }

}