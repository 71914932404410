.footer {
	margin-top: 5rem;
	padding: 0rem 0rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	min-height: 5vh;
	background-color: #ee750a;
	color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 5%);
	font-size: 1rem;
	.centro{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.yan {
		a {
			text-decoration: none;
			color: white;
			transition: all .3s;
			&:hover{
				color: rgb(255, 255, 255,0.5)
			}
		}
	}

	.icons {
		font-size: 2rem;
		a{
			color: white;
			transition: all .3s;
			&:hover{
				color: rgb(255, 255, 255,0.5)
			}
		}
	}
	.cnpj{
		font-size: 0.8rem;
	}
}