.mensagem-erro{
    display: flex;
    justify-content: center;
    margin-top: 10%;
}
.error{
    display: flex;
    justify-content: center;
    align-items: center;
    .texto{
        span{
            font-size: 100px;
        } 
    }
    
}