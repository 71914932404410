.Hero {

    min-height: 80vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    // background-image: url("https://img.freepik.com/fotos-gratis/interior-de-oficina-de-fabrica-e-maquinas-em-fundo-de-producao-de-vidro_645730-396.jpg?w=1380&t=st=1686840218~exp=1686840818~hmac=db3501332727a9edbdaee5bc229e87e7844ea71f1d012bad5a45abd64e5e44a9");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    i {
        color: #f07d0e
    }

    div {
        width: 50%;
    }

    .txt {
        .hero_text {
            font-family: 'Montserrat', sans-serif;
            font-size: 4rem;
            margin-bottom: 40px;
        }
        h2{
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            // font-weight: bold;
            // margin-left: 1rem;
            margin-bottom: 2rem;
            color: #f07d0e
        }
    }


    .ContactButton {
        text-decoration: none;
        font-size: large;
        padding: 15px;
        color: white;
        background-color: #f07d0e;
        border-radius: 5px;
        transition: all .3s;

        &:hover {
            background-color: rgb(240, 125, 14, 0.8);
        }
    }

    .imgBg {
        background-image: url("./hero.png");
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        min-height: 26.6rem;
    }
}

@media (max-width: 995px) {
    .Hero {
        div {
            width: 100%;
        }
    }

    .imgBg {
        display: none;
    }
}

@media (max-width: 460px) {
    .hero_text {
        font-size: 3rem !important;
    }

}

@media (max-width: 380px) {
    .hero_text {
        font-size: 2rem !important;
    }

}




// #f07d0e

// https://br.freepik.com/vetores-gratis/ilustracao-do-conceito-de-servico-de-limpeza_28097896.htm#page=10&query=cleaning&position=22&from_view=search&track=sph