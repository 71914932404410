.why {
    .titulo {
        font-size: 3rem;
        font-family: 'Montserrat', sans-serif;
        color: #f07d0e
    }

    .sobre {
        padding: 1rem 0px 1rem 0px;
        min-height: 60vh;
        .conteudo {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .texto {
                line-height: 1.4;
                font-size: 1.2rem;
                padding-top: 11px;
                text-align: center;
            }
        }

        .img {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 25rem;
            }
        }
    }
}

.carrosel {
    gap: 5px;

    img {
        max-height: 90px;
    }
}

@media (max-width: 768px)
{
  .sobre.direita{
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 500px) {
    .valores {
        padding: 0px 1rem !important;
    }

}
@media (max-width: 400px) {
    .subt {
        font-size: 1.5rem  !important;
    }
    .img {
        img {
            width: 15rem !important;
        }
    }

}

// border: 5px solid white;
//     padding: 20px 30px;
//     font-size: 40px;
//     border-radius: 180px;