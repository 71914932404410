.container-fluid .bglaranja {
    background-color: #f07d0e;

    .valores {
        padding: 0px 5rem;
        margin-bottom: 5rem;
        color: #ffffff;
        max-width: 100%;
        margin: 0;

        .titulo {
            margin-bottom: 2rem;
            margin-top: 2rem;
            font-family: 'Montserrat', sans-serif;
            color: #ffffff;
        }

        .Card {
            text-align: center;

            .subt {
                font-size: 2rem;
                margin-top: 1rem;
                font-family: 'Montserrat', sans-serif;
                color: white;
            }

            .texto {
                font-size: 1.2rem;
                padding-top: 2rem;
                line-height: 1.2;
                padding-bottom: 2rem;
            }

            img {
                width: 10rem;
            }
        }

    }

}