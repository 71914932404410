.titulo {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: #f07d0e
}



.contato {
    // padding: 1rem 0px;
    svg {
        font-size: 2rem;
        color: #f07d0e;
        margin-bottom: 10px;
    }
    .conteudo {
        text-align: center;
        padding: 0rem 2px;

        .subt {
            font-size: 2rem;
            font-family: 'Montserrat', sans-serif;
            color: #f07d0e;
            margin-top: 3rem;
        }

        .horario {
            .texto {
                margin-bottom: 1rem;
            }

            .negr {
                font-weight: bold;
                margin-top: 10px;
            }

            svg {
                font-size: 2rem;
                color: #f07d0e;
                margin-bottom: 10px;
            }
        }

        .contactCard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px;
            margin-top: 15px;
            margin-bottom: 10px;

            .texto {
                margin: 10px 0px;
            }

            a {
                color: black;
                text-decoration: none;
                font-weight: bold;
                margin: 0px 5px;
            }

            svg {
                font-size: 2rem;
                color: #f07d0e;
            }

        }

        ul {
            li {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-top: 15px;
            }
        }

    }

    iframe {
        margin-bottom: 15px;
    }
}