.stats {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    margin-top: 4rem;
    margin-bottom: 4rem;
    .cardd {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2{
            font-size: 3rem;
            font-family: 'Montserrat', sans-serif;
            color:#f07d0e;
            margin-bottom: 10px;
        }
        h3{
            font-size: 1.5rem;
            text-wrap: nowrap;
        }
        .img {
            height: 7rem;
            width: 100%;
            margin-bottom: 1rem;
            margin-top: 3rem;
        }

        .caixas {
            background-image: url("./img/caixas.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .entregas{
            background-image: url("./img/entregasFeitas.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .caminhao{
            background-image: url("./img/caminhaoEntrega.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .cliente{
            background-image: url("./img/cliente.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}